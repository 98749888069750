import { Component } from '@angular/core';

declare var $:any;

@Component({
    moduleId: module.id,
    selector: 'notifications-cmp',
    templateUrl: './notifications.component.html'
})

export class NotificationsComponent{
    COLORS_CODES={
        info : 1,
        success :2,
        warning:3,
        error:4,
        show:5
      }
    showListInModal(array){
        
    }
        showNotification(from, align,color,msg){
        console.log("###########showing notificationssssssss");
        console.log("showing notificationssssssss");
        var type = ['','info','success','warning','danger'];

       // var color = Math.floor((Math.random() * 4) + 1);

    	$.notify({
        	icon: "ti-gift",
        	message: msg
        },{
            type: type[color],
            timer: 10,
            placement: {
                from: from,
                align: align
            },
            template: '<div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert"><button type="button" aria-hidden="true" class="close" data-notify="dismiss"><i class="nc-icon nc-simple-remove"></i></button><span data-notify="icon" class="nc-icon nc-bell-55"></span> <span data-notify="title">{1}</span> <span data-notify="message">{2}</span><div class="progress" data-notify="progressbar"><div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" target="{4}" data-notify="url"></a></div>'
        });
	}
}
