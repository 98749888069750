import {HttpErrorResponse, HTTP_INTERCEPTORS} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';

import {TokenStorageService} from '../_services/token-storage.service';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NotificationsComponent} from 'app/components/notifications/notifications.component';
import {MatDialog} from '@angular/material/dialog';
import {TechDialogElements} from '../common/dialog/TechDialogElements';
import { AuthService } from '../core/auth.service';


const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end
// const TOKEN_HEADER_KEY = 'x-access-token';   // for Node.js Express back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private token: TokenStorageService,
         private notifComponent: NotificationsComponent,
                public dialog: MatDialog,
                private auth:AuthService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        let authReq = req;
        let curUrl = req.url;
        const token = this.token.getToken();
        console.log('in interceptor');
        // console.log("token",token);
        if (curUrl.startsWith('/gts')) { 
            authReq = req.clone({
             //      url: 'http://localhost:9095' + req.url,
             url: 'https://general-transit-service.com/gts' + req.url,
                headers: req.headers.set(TOKEN_HEADER_KEY, token) 
            });
        }
        return next.handle(authReq).pipe(
            catchError((error: HttpErrorResponse) => {
               var status=error.error.status;
                if( error.error.message instanceof String ){
                    error.error.message=[error.error.message];
                }if(!status){
                    status=error.status;
                }
                console.log('testt 300000',error)
                if (error.error instanceof ErrorEvent) {
                    // A client-side or network error occurred. Handle it accordingly.
                    console.log('A client-side or network error occurred');
                    console.log('An error occurred:', error.error.message);
                
                } else { 
                if ("404".endsWith(status )) {
                   this.dialog.open(TechDialogElements, {
                            data: {
                                titre: 'Erreur!',
                                message: ["Veuillez contacter votre Administrateur"]
                            }
                        });
                    
                }
                else if ("405".endsWith(status)) {
                    this.dialog.open(TechDialogElements, {
                             data: {
                                 titre: 'Erreur!',
                                 message: ["Veuillez contacter votre Administrateur"]
                             }
                         });
                 }
                else if ("400".endsWith(status)) { 
                    console.log('testt 400000')
                            this.dialog.open(TechDialogElements, {
                                data: {
                                    titre: 'Erreur!',
                                    message: error.error
                                }
                            }); 
                    }
                   else if ("403".endsWith(status)) {
                    this.auth.logout();

                    }  
                }
                // Return an observable with a user-facing error message.
                console.log('Something bad happened; please try again later.!!')
                return throwError(
                    'Something bad happened; please try again later.');
            })
        );
    }

}

export const authInterceptorProviders = [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
];

