import { Routes } from '@angular/router'; 
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';  
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { UsersComponent } from './pages/users/users.component'; 
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs'; 

export const AppRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [],
    data: {}
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './pages/pages.module#PagesModule'
      },
      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      }, {
        path: '',
        loadChildren: './userpage/user.module#UserModule'
      }]
  }
];
